// // // rem等比适配配置文件
// function setRem() {
// 	//最终分辨率为3840*2160
// 	let baseSize = 50
// 	const scale = document.documentElement.clientWidth / 1920
// 	window.scale = scale
// 	let size = baseSize * Math.min(scale, 2)
// 	document.getElementsByTagName('body')[0].style.background = 'transparent'
// 	document.documentElement.style.fontSize = size + 'px'
// }
// setRem()
// window.onresize = function () {
// 	setRem()
// }

// 使用方式
// main.js中引入
// import '@/utils/rem.js'

// 设置字体大小
// const setFontSize = (val) => {
// 	const baseSize = 50
// 	let scale = document.documentElement.clientWidth / 1920
// 	let nowSize = baseSize * Math.min(scale, 2) //返回值函数内部最小的值
// 	return val * nowSize
// }

// rem等比适配配置文件
function setRem() {
	const clientWidth = document.documentElement.clientWidth
	if (clientWidth > 750) {
		document.documentElement.style.fontSize = '100px'
	} else {
		document.documentElement.style.fontSize = 100 * (clientWidth / 750) + 'px'
	}
}
setRem()
window.onresize = function () {
	setRem()
}
