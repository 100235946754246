import { createApp } from 'vue'
import { createPinia } from 'pinia'
// 引入自定义css
import './style/index.scss'
// 引入rem
import './utils/rem.js'
// 引入dayjs
import dayjs from 'dayjs'

import App from './App.vue'
import router from './router'

const app = createApp(App)

// 引入全局自定义指令
import * as directive from './global/directive'
Object.keys(directive['default']).forEach((key) => {
	app.directive(key, directive['default'][key])
})

// 引入vant
import { Toast } from 'vant'
import 'vant/lib/index.css'
app.use(Toast)
// 挂载全局方法
app.config.globalProperties.$dayjs = dayjs

app.use(createPinia())
app.use(router)
app.use(dayjs)

app.mount('#app')
