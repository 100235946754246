import { useUserStore } from '@/store/user'
// 按钮权限
const has = {
	mounted(el, bindings) {
		const userStore = useUserStore()
		const item = userStore.roleButtonList.find((item) => item.path === bindings.value)
		if (!item) {
			el.parentNode && el.parentNode.removeChild(el)
		}
	}
}

export default {
	has
}
