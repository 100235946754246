import { defineStore } from 'pinia'
import router from '@/router/index.js'

export const useUserStore = defineStore('user', {
	state: () => ({
		// 用户信息
		userInfo: {
			userName: '管理员'
		}
	}),
	actions: {}
})
