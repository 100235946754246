import { createRouter, createWebHistory } from 'vue-router'
import routes from './route'

// 创建路由
const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes
})

export default router
