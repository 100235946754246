const routes = [
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/home.vue')
	},
	{
		path: '/openVip',
		name: 'openVip',
		component: () => import('../views/openVip/openVip.vue')
	},
]
export default routes
